<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>物流管理</el-breadcrumb-item>
        <el-breadcrumb-item>地址管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">发货人：</div>
                <el-input v-model="search.shipName" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">手机号：</div>
                <el-input :maxLength="11" v-model="search.phone" placeholder="搜索"></el-input>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="addressAdd" @click="goAdd">新增地址</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="shipName" label="发货人"></el-table-column>
            <el-table-column align="center" prop="phone" label="手机号"></el-table-column>
            <el-table-column align="center" prop="abbreviation" label="地址简称"></el-table-column>
            <el-table-column align="center" label="发货地址" min-width="300">
              <template slot-scope="scope">
                <span class="contennt-desc">{{ scope.row.simAddress }}{{ scope.row.detailAddress }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="shipAddress" label="设为发货点">
              <template slot-scope="scope">
                <el-switch
                    @change="changeShipAddress(scope.row,$event)"
                    :disabled="addressUpd?false:true"
                    v-model="scope.row.shipAddress"
                    active-color="#409EFF"
                    inactive-color="#ff4949">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="takeAddress" label="设为收货点">
              <template slot-scope="scope">
                <el-switch
                    @change="changeTakeAddress(scope.row,$event)"
                    :disabled="addressUpd?false:true"
                    v-model="scope.row.takeAddress"
                    active-color="#409EFF"
                    inactive-color="#ff4949">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="addressUpd" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="addressDel" @click="del(scope.row.id)">删除</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        :title="type === 'details' ? '详情' : type === 'edit' ? '修改' : '新增'"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
    >
      <el-form v-if="upData && formDataReady" :model="upData" :rules="rules" ref="ruleForm">
        <el-form-item label="联系人：" prop="shipName">
          <span v-if="type === 'details'">{{ upData.shipName }}</span>
          <el-input v-else v-model="upData.shipName" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <span v-if="type === 'details'">{{ upData.phone }}</span>
          <el-input v-else v-model="upData.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="地址简称：" prop="abbreviation">
          <span v-if="type === 'details'">{{ upData.abbreviation }}</span>
          <el-input v-else v-model="upData.abbreviation" placeholder="请输入地址简称"></el-input>
        </el-form-item>
        <el-form-item label="发货地址：" v-if="type === 'details'" prop="detailAddress" style="width: 100%;">
          <span>{{ upData.simAddress }}{{ upData.detailAddress }}</span>
        </el-form-item>
        <el-form-item label="地址：" v-if="type !== 'details'" prop="simAddressList">
          <area-cascader type="text" :placeholder="upData.simAddress" v-model="upData.simAddressList" :data="$pcaa"
                         :level="2"></area-cascader>
        </el-form-item>
        <el-form-item label="详细地址：" v-if="type !== 'details'" prop="detailAddress" style="width: 100%;">
          <span v-if="type === 'details'">{{ upData.detailAddress }}</span>
          <el-input
              v-else
              type="textarea"
              placeholder="请输入详细地址"
              v-model="upData.detailAddress"
              :autosize="{ minRows: 6, maxRows: 8}"
              maxlength="100"
              show-word-limit
          />
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="设为发货点：">
              <span v-if="type === 'details'">{{ upData.shipAddress ? '是' : '否' }}</span>
              <el-switch
                  v-else
                  v-model="upData.shipAddress"
                  active-color="#409EFF"
                  inactive-color="#ff4949">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设为收货点：">
              <span v-if="type === 'details'">{{ upData.takeAddress ? '是' : '否' }}</span>
              <el-switch
                  v-else
                  v-model="upData.takeAddress"
                  active-color="#409EFF"
                  inactive-color="#ff4949">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>


      </el-form>
      <span v-if="type === 'details'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">返 回</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="add" v-if="type !== 'details'">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validateSimAddressList = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请选择地址'));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      search: {
        shipName: "",
        phone: ""
      },
      placeholder: '请选择地址',
      type: "",
      tableList: [],
      dialogVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogImgUrl: "",
      upData: {},
      rules: {
        abbreviation: [{required: true, message: "请输入地址简称", trigger: "blur"}],
        detailAddress: [{required: true, message: "请输入详细地址", trigger: "blur"}],
        shipAddress: [{required: true, message: "请选择地址", trigger: "blur"}],
        shipName: [{required: true, message: "请输入收货人", trigger: "blur"}],
        simAddressList: [{required: true, validator: validateSimAddressList, trigger: "blur"}],
        phone: [{required: true, validator: validatePhone, trigger: "blur"}],
      },
      selected: [],
      addressAdd: false,
      addressDel: false,
      addressUpd: false,
      total: 0,
      page: 1,
      pageSize: 10
    };
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;

      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getAddress(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              const list = res.data.data.records
              list.forEach(item => {
                item.takeAddress = item.takeAddress === 0 ? false : true;
                item.shipAddress = item.shipAddress === 0 ? false : true;
              })
              _this.tableList = list;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "address:add") {
          _this.addressAdd = true;
        }
        if (v == "address:del") {
          _this.addressDel = true;
        }
        if (v == "address:upd") {
          _this.addressUpd = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    changeShipAddress(item, e) {
      let _this = this;
      const resqusetData = JSON.parse(JSON.stringify(item));
      resqusetData.shipAddress = e ? 1 : 0;
      resqusetData.takeAddress = e ? 1 : 0;

      _this.$api.editAddress(resqusetData).then(res => {
        if (res.data.code == 200) {

        }
      }).catch(() => {

      });
    },
    changeTakeAddress(item, e) {
      let _this = this;
      const resqusetData = JSON.parse(JSON.stringify(item));
      resqusetData.shipAddress = e ? 1 : 0;
      resqusetData.takeAddress = e ? 1 : 0;
      _this.$api.editAddress(resqusetData).then(res => {
        if (res.data.code == 200) {

        }
      }).catch(() => {

      });
    },
    add() {
      let _this = this;
      _this.btnLoading = true;

      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(_this.upData));
          data.shipAddress = data.shipAddress ? 1 : 0;
          data.takeAddress = data.takeAddress ? 1 : 0;
          data.simAddress = data.simAddressList.toString();
          delete data.simAddressList;

          // return
          if (!_this.upData.id) {
            _this.$api.addAddress(data).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("添加成功");
                _this.btnLoading = false;
                _this.handleClose();
                _this.getData();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          } else {
            _this.$api.editAddress(data).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("修改成功");
                _this.btnLoading = false;
                _this.getData();
                _this.handleClose();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          }
        } else {
          _this.btnLoading = false;
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.upData = {
        abbreviation: "",
        detailAddress: "",
        phone: "",
        shipAddress: true,
        shipName: "",
        simAddressList: [],
        simAddress: "请选择地址",
        takeAddress: true,
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        shipName: "",
        phone: ""
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },
    goAdd() {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'add';
      _this.upData = {
        abbreviation: "",
        detailAddress: "",
        phone: "",
        simAddressList: [],
        simAddress: "请选择地址",
        shipAddress: true,
        shipName: "",
        takeAddress: true,
      }
      _this.upList = [];
      _this.handleInitForm();
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'details';
      _this.upData = JSON.parse(JSON.stringify(e));
    },
    edit(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'edit';
      _this.upData = JSON.parse(JSON.stringify(e));
      _this.upData.simAddressList = _this.upData.simAddress.split(',');
    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delAddress({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-textarea {
  //::-webkit-scrollbar{
  //  width:0;
  //  height:0;
  //}
  /deep/ .el-input__count {
    //right: 50px;
  }
}

.el-input-number {
  width: 100%;

  /deep/ .el-input__inner {
    text-align: left;
  }
}

.goods-box {
  margin-bottom: 20px;

  .search-title {
    width: 100px;
  }
}

/deep/ .el-dialog {
  margin-top: 5vh;
}

/deep/ .area-select.large {
  width: 100%;
  height: 40px;
}

/deep/ .area-select .area-selected-trigger {
  padding: 0 15px;
}
</style>
